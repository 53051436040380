import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { Routes } from '~/domains/common/constants/routes';
import { useTreatments } from '~/domains/common/hooks/useTreatments';

export const useInvitesManagerEnabled = () => {
  const { isReady, treatments } = useTreatments([FEATURE.ENABLE_INVITES_MANAGER_ALL]);
  const router = useRouter();

  const navigateToInvitesManager = useCallback(
    (contestId: string | undefined) => {
      if (!contestId) return;
      router.push(Routes.invitesManager(contestId));
    },
    [router]
  );

  const isInvitesManagerEnabled =
    treatments[FEATURE.ENABLE_INVITES_MANAGER_ALL]?.treatment === 'on';

  return {
    isReady,
    isInvitesManagerEnabled,
    navigateToInvitesManager,
  };
};
