const enum FEATURE {
  ENABLE_BETA_WITHDRAWAL_BLOCKER = 'ENABLE_BETA_WITHDRAWAL_BLOCKER',
  ENABLE_CONTEST_DETAILS_JOIN_AND_DEPOSIT_BUTTONS = 'ENABLE_CONTEST_DETAILS_JOIN_AND_DEPOSIT_BUTTONS',
  ENABLE_LOCATION_CHECK_ON_LOGIN = 'ENABLE_LOCATION_CHECK_ON_LOGIN',
  ENABLE_PHASE_TWO_LOBBY_FILTERS = 'ENABLE_PHASE_TWO_LOBBY_FILTERS',
  SHOW_CONTEST_DETAILS_ENTRANTS_TAB = 'SHOW_CONTEST_DETAILS_ENTRANTS_TAB',
  SHOW_ERROR_PAGE_ERROR_SCREEN = 'SHOW_ERROR_PAGE_ERROR_SCREEN',
  SHOW_LOBBY_TOGGLE_VIEW_BUTTONS = 'SHOW_LOBBY_TOGGLE_VIEW_BUTTONS',
  SHOW_MENU_TABLE_COMMISSIONER_CONTESTS = 'SHOW_MENU_TABLE_COMMISSIONER_CONTESTS',
  SHOW_MY_ENTRIES_CONTEST_STATS = 'SHOW_MY_ENTRIES_CONTEST_STATS',
  SHOW_PLAYER_PICKER_FOOTER_CHAT = 'SHOW_PLAYER_PICKER_FOOTER_CHAT',
  SHOW_PLAYER_PICKER_FOOTER_LEADERS = 'SHOW_PLAYER_PICKER_FOOTER_LEADERS',
  SHOW_PLAYER_PICKER_FOOTER_STANDINGS = 'SHOW_PLAYER_PICKER_FOOTER_STANDINGS',
  SHOW_SCORE_ON_PAR_CONTESTS = 'SHOW_SCORE_ON_PAR_CONTESTS',
  USE_PAYPAL_INTERNAL_SIMULATOR = 'USE_PAYPAL_INTERNAL_SIMULATOR',
  USE_TEST_COMMISSIONER_CHANNEL_DATA = 'USE_TEST_COMMISSIONER_CHANNEL_DATA',
  SHOW_KYC_INTRO_MODAL = 'SHOW_KYC_INTRO_MODAL',
  ENABLE_NEW_KYC_FORM = 'ENABLE_NEW_KYC_FORM',
  ENABLE_COMMISSIONER_FOLLOWERS = 'ENABLE_COMMISSIONER_FOLLOWERS',
  SHOW_COMMISSIONER_ONBOARDING_CTA = 'SHOW_COMMISSIONER_ONBOARDING_CTA',
  ENABLE_COMMISSIONER_POSTS = 'ENABLE_COMMISSIONER_POSTS',
  ENABLE_CONTEST_LOBBY_LIGHT_THEME = 'ENABLE_CONTEST_LOBBY_LIGHT_THEME',
  ENABLE_SPORT_FILTER = 'ENABLE_SPORT_FILTER',
  ENABLE_CONTEST_TYPE_FILTER = 'ENABLE_CONTEST_TYPE_FILTER',
  ENABLE_CMS_CAROUSEL = 'ENABLE_CMS_CAROUSEL',
  ENABLE_DFS_GAME_UNITS = 'ENABLE_DFS_GAME_UNITS',
  CREATE_CONTEST_ENABLE_PUBLIC = 'CREATE_CONTEST_ENABLE_PUBLIC',
  CREATE_CONTEST_ENABLE_HUB_CTA = 'CREATE_CONTEST_ENABLE_HUB_CTA',
  CREATE_CONTEST_ENABLE_IMAGE_UPLOAD = 'CREATE_CONTEST_ENABLE_IMAGE_UPLOAD',
  SHOW_DEFAULT_CAROUSEL_CARD = 'SHOW_DEFAULT_CAROUSEL_CARD',
  SHOW_SECOND_CAROUSEL_CARD = 'SHOW_SECOND_CAROUSEL_CARD',
  SHOW_THIRD_CAROUSEL_CARD = 'SHOW_THIRD_CAROUSEL_CARD',
  SHOW_COMMISSIONER_ONBOARDING_VIDEO = 'SHOW_COMMISSIONER_ONBOARDING_VIDEO',
  ENABLE_CONTEST_DETAILS_BANNER_AND_AVATAR = 'ENABLE_CONTEST_DETAILS_BANNER_AND_AVATAR',
  SHOW_PUBLIC_PRIVATE_POSTS = 'SHOW_PUBLIC_PRIVATE_POSTS',
  SHOW_OAUTH_OPTIONS = 'SHOW_OAUTH_OPTIONS',
  CREATE_CONTEST_ENABLE_CUSTOM_FEE_AND_ENTRANTS = 'CREATE_CONTEST_ENABLE_CUSTOM_FEE_AND_ENTRANTS',
  SHOW_CONTEST_INVITE_CTA = 'SHOW_CONTEST_INVITE_CTA',
  ENABLE_SURVIVORS_PICKS = 'ENABLE_SURVIVORS_PICKS',
  SHOW_CONTEST_ITEM_THUMBNAIL = 'SHOW_CONTEST_ITEM_THUMBNAIL',
  SHOW_VIPP_AND_PAPER_CHECK = 'SHOW_VIPP_AND_PAPER_CHECK',
  ENABLE_POST_COMMENTS = 'ENABLE_POST_COMMENTS',
  CREATE_CONTEST_ENABLE_REWARD_OPT_OUT = 'CREATE_CONTEST_ENABLE_REWARD_OPT_OUT',
  ENABLE_COMMISSIONER_ONBOARDING_STEP_2 = 'ENABLE_COMMISSIONER_ONBOARDING_STEP_2',
  SHOW_OAUTH_RYP = 'SHOW_OAUTH_RYP',
  SHOW_OAUTH_OFP = 'SHOW_OAUTH_OFP',
  CREATE_CONTEST_SHOW_NFL_BANNER = 'CREATE_CONTEST_SHOW_NFL_BANNER',
  CREATE_CONTEST_ENABLE_ENTRY_APPROVAL = 'CREATE_CONTEST_ENABLE_ENTRY_APPROVAL',
  ENABLE_INVITE_MODAL_FETCH_500_USERS = 'ENABLE_INVITE_MODAL_FETCH_500_USERS',
  JURISDICTION_ENABLE_INFO_CONTEST_DETAIL = 'JURISDICTION_ENABLE_INFO_CONTEST_DETAIL',
  JURISDICTION_ENABLE_INFO_LOBBY = 'JURISDICTION_ENABLE_INFO_LOBBY',
  JURISDICTION_ENABLE_FLAGGING_LOBBY = 'JURISDICTION_ENABLE_FLAGGING_LOBBY',
  ENABLE_PICKEM_PICKS = 'ENABLE_PICKEM_PICKS',
  SHOW_ALL_OPTION_ON_INVITES = 'SHOW_ALL_OPTION_ON_INVITES',
  ENABLE_REPORT_POST = 'ENABLE_REPORT_POST',
  ENABLE_COMMISSIONER_REWARDS = 'ENABLE_COMMISSIONER_REWARDS',
  ENABLE_HOME_PAGE = 'ENABLE_HOME_PAGE',
  ENABLE_HOME_PAGE_LIGHT_THEME = 'ENABLE_HOME_PAGE_LIGHT_THEME',
  KYC_PRIMARY_DEPOSIT_CTA = 'KYC_PRIMARY_DEPOSIT_CTA',
  ENABLE_PICKEM_LEADERBOARD = 'ENABLE_PICKEM_LEADERBOARD',
  ENABLE_SURVIVOR_LEADERBOARD = 'ENABLE_SURVIVOR_LEADERBOARD',
  KYC_CMS_ZONE = 'KYC_CMS_ZONE',
  CREATE_CONTEST_SHOW_DESCRIPTION_FIELD = 'CREATE_CONTEST_SHOW_DESCRIPTION_FIELD',
  SHOW_ASIDE_STATS_OWNERS = 'SHOW_ASIDE_STATS_OWNERS',
  SHOW_ASIDE_STATS_LAST_GAMES = 'SHOW_ASIDE_STATS_LAST_GAMES',
  PAYPAL_DEPOSIT_SUCCESS_DELAY = 'PAYPAL_DEPOSIT_SUCCESS_DELAY',
  ENABLE_ANONYMOUS_PICKS = 'ENABLE_ANONYMOUS_PICKS',
  SHOW_ADD_ALL_USERS_ON_INVITES = 'SHOW_ADD_ALL_USERS_ON_INVITES',
  CREATE_CONTEST_ENABLE_QUICK_CREATE = 'CREATE_CONTEST_ENABLE_QUICK_CREATE',
  ENABLE_SURVIVOR_STATS = 'ENABLE_SURVIVOR_STATS',
  ENABLE_PICKEM_STATS = 'ENABLE_PICKEM_STATS',
  ENABLE_COMMISSIONER_SEARCH = 'ENABLE_COMMISSIONER_SEARCH',
  SHOW_POST_PICK_MODAL = 'SHOW_POST_PICK_MODAL',
  ENABLE_COMMISSIONER_LIST_INTERCOM__CONTEST_DETAILS = 'ENABLE_COMMISSIONER_LIST_INTERCOM__CONTEST_DETAILS',
  ENABLE_COMMISSIONER_LIST_INTERCOM__MY_ACCOUNT = 'ENABLE_COMMISSIONER_LIST_INTERCOM__MY_ACCOUNT',
  ENABLE_COMMISSIONER_LIST_INTERCOM__COMMISH_HUB = 'ENABLE_COMMISSIONER_LIST_INTERCOM__COMMISH_HUB',
  ENABLE_NFL_PLAYER_SEASON_AVERAGES = 'ENABLE_NFL_PLAYER_SEASON_AVERAGES',
  ENABLE_NFL_PLAYER_FPTS = 'ENABLE_NFL_PLAYER_FPTS',
  ENABLE_POST_PICK_MODAL_FEATURED_CONTESTS = 'ENABLE_POST_PICK_MODAL_FEATURED_CONTESTS',
  DISABLED_PAYMENT_METHODS = 'DISABLED_PAYMENT_METHODS',
  ENABLE_SALARY_BASED_CONTESTS = 'ENABLE_SALARY_BASED_CONTESTS',
  ENABLE_PLAYER_TIER_BASKETBALL_PICKS = 'ENABLE_PLAYER_TIER_BASKETBALL_PICKS',
  ENABLE_NEW_GAMESHELL_FOR_CONTEST_TYPE = 'ENABLE_NEW_GAMESHELL_FOR_CONTEST_TYPE',
  TEST_COMMISSIONER_REWARDS_OVERRIDE = 'TEST_COMMISSIONER_REWARDS_OVERRIDE',
  SHOW_MAINTENANCE_BANNER = 'SHOW_MAINTENANCE_BANNER',
  SHOW_MAINTENANCE_SCREEN = 'SHOW_MAINTENANCE_SCREEN',
  ENABLE_SKIP_WELCOME_SCREEN = 'ENABLE_SKIP_WELCOME_SCREEN',
  CREATE_CONTEST_ENABLE_FORM_DEBUG_INFO = 'CREATE_CONTEST_ENABLE_FORM_DEBUG_INFO',
  ENABLE_VENMO = 'ENABLE_VENMO',
  ENABLE_REFER_A_FRIEND = 'ENABLE_REFER_A_FRIEND',
  CREATE_CONTEST_NFL_TIERS_OPTION_TAGS = 'CREATE_CONTEST_NFL_TIERS_OPTION_TAGS',
  CREATE_CONTEST_ALLOW_SELECT_INDIVIDUAL_GAMES = 'CREATE_CONTEST_ALLOW_SELECT_INDIVIDUAL_GAMES',
  CREATE_CONTEST_ALLOW_SET_PICK_DEADLINE = 'CREATE_CONTEST_ALLOW_SET_PICK_DEADLINE',
  CREATE_CONTEST_ALLOW_WEIGHTED_SCORING = 'CREATE_CONTEST_ALLOW_WEIGHTED_SCORING',
  CREATE_CONTEST_NFL_PICK_X_OPTION_TAGS = 'CREATE_CONTEST_NFL_PICK_X_OPTION_TAGS',
  ENABLE_CONTEST_COUNTDOWN_TIMER = 'ENABLE_CONTEST_COUNTDOWN_TIMER',
  SHOW_PROMOTION_LINKS_IN_SUBNAV = 'SHOW_PROMOTION_LINKS_IN_SUBNAV',
  ENABLE_NEW_MY_CONTESTS_PAGE = 'ENABLE_NEW_MY_CONTESTS_PAGE',
  ENABLE_MY_CONTESTS_PAGE_LIGHT_THEME = 'ENABLE_MY_CONTESTS_PAGE_LIGHT_THEME',
  ENABLE_MY_CONTESTS_PILLS = 'ENABLE_MY_CONTESTS_PILLS',
  CREATE_CONTEST_ALLOW_BEST_PICK = 'CREATE_CONTEST_ALLOW_BEST_PICK',
  ENABLE_COMMISSIONER_INSIGHTS = 'ENABLE_COMMISSIONER_INSIGHTS',
  ENABLE_COMMISSIONER_ABOUT = 'ENABLE_COMMISSIONER_ABOUT',
  ENABLE_COMMISSIONER_MEMBERS = 'ENABLE_COMMISSIONER_MEMBERS',
  /** @deprecated - replaced with Strapi on contests-service */
  CREATE_CONTEST_SLATE_GROUP_GROUPS = 'CREATE_CONTEST_SLATE_GROUP_GROUPS',
  ENABLE_FILTER_LEAGUE = 'ENABLE_FILTER_LEAGUE',
  CREATE_CONTEST_ENABLE_1_POINTS_5 = 'CREATE_CONTEST_ENABLE_1_POINTS_5',
  ENABLE_CHAT = 'ENABLE_CHAT',
  ENABLE_NEW_WITHDRAWAL_FLOW = 'ENABLE_NEW_WITHDRAWAL_FLOW',
  MOBILE_DEEP_LINKING = 'MOBILE_DEEP_LINKING', // TODO remove
  ENABLE_BASKETBALL_CONTESTS = 'ENABLE_BASKETBALL_CONTESTS',
  ENABLE_CHANNELS_V1 = 'ENABLE_CHANNELS_V1',
  ENABLE_DEBUG_MENU = 'ENABLE_DEBUG_MENU',
  ENABLE_DYNAMIC_CONTEST_LINK_PREVIEW = 'ENABLE_DYNAMIC_CONTEST_LINK_PREVIEW',
  ENABLE_DYNAMIC_CONTEST_LINK_PREVIEW_BASE64 = 'ENABLE_DYNAMIC_CONTEST_LINK_PREVIEW_BASE64',
  ENABLE_DYNAMIC_CONTEST_LINK_PREVIEW_DEADLINE = 'ENABLE_DYNAMIC_CONTEST_LINK_PREVIEW_DEADLINE',
  REFETCH_INTERVAL_FOR_TEAM_BASED_PICKSHEET = 'REFETCH_INTERVAL_FOR_TEAM_BASED_PICKSHEET',
  ALLOW_LIVE_GAMESHELL_POINTS = 'ALLOW_LIVE_GAMESHELL_POINTS',
  ENABLE_CANADA_IN_KYC_AND_BILLING_ADDRESS = 'ENABLE_CANADA_IN_KYC_AND_BILLING_ADDRESS',
  CREATE_CONTEST_ENABLE_CHAT = 'CREATE_CONTEST_ENABLE_CHAT',
  ENABLE_DFS_MULTI_SLATE = 'ENABLE_DFS_MULTI_SLATE',
  ENABLE_NEW_GAMESHELL_V2_FOR_CONTEST_TYPE = 'ENABLE_NEW_GAMESHELL_V2_FOR_CONTEST_TYPE',
  ENABLE_NEW_HEADER_2 = 'ENABLE_NEW_HEADER_2',
  ENABLE_APP_PROMPT = 'ENABLE_APP_PROMPT',
  ENABLE_CREATE_CONTEST_FROM_PREVIOUS_SETTINGS = 'ENABLE_CREATE_CONTEST_FROM_PREVIOUS_SETTINGS',
  USE_SKRILL_INTERNAL_SIMULATOR = 'USE_SKRILL_INTERNAL_SIMULATOR',
  GAMECENTER_UNSUPPORTED_TYPES_AND_FEATURES = 'GAMECENTER_UNSUPPORTED_TYPES_AND_FEATURES',
  DISABLE_PLAYER_BASED_CONTESTS = 'DISABLE_PLAYER_BASED_CONTESTS',
  SHOW_NEW_SIGN_UP = 'SHOW_NEW_SIGN_UP',
  SHOW_NEW_SIGN_IN = 'SHOW_NEW_SIGN_IN',
  ENABLE_PROPS = 'ENABLE_PROPS',
  ENABLE_ENTRIES_PAGE = 'ENABLE_ENTRIES_PAGE',
  ENABLE_EDIT_CONTEST = 'ENABLE_EDIT_CONTEST',
  ENABLE_EDIT_CONTEST_ADVANCED_SETTINGS = 'ENABLE_EDIT_CONTEST_ADVANCED_SETTINGS',
  ENABLE_RECAPTCHA = 'ENABLE_RECAPTCHA',
  ENABLE_INVITES_MANAGER = 'ENABLE_INVITES_MANAGER',
  ENABLE_REFER_A_FRIEND_V2 = 'ENABLE_REFER_A_FRIEND_V2',
  ENABLE_SURVIVOR_AVAILABILITY_PAGE = 'ENABLE_SURVIVOR_AVAILABILITY_PAGE',
  ENABLE_QUICK_PICKS_CANCEL_ENTRY = 'ENABLE_QUICK_PICKS_CANCEL_ENTRY',
  ENABLE_INVITES_MANAGER_FOLLOWERS = 'ENABLE_INVITES_MANAGER_FOLLOWERS',
  ENABLE_INVITES_MANAGER_GROUPS = 'ENABLE_INVITES_MANAGER_GROUPS',
  ENABLE_POPULAR_CHANNELS_ON_HOME = 'ENABLE_POPULAR_CHANNELS_ON_HOME',
  ENABLE_PLAY_WITH_PEOPLE_YOU_FOLLOW = 'ENABLE_PLAY_WITH_PEOPLE_YOU_FOLLOW',
  ENABLE_INVITES_MANAGER_RYP = 'ENABLE_INVITES_MANAGER_RYP',
  ENABLE_SLIP_DEBUG_BLOCK = 'ENABLE_SLIP_DEBUG_BLOCK',
  ENABLE_INVITES_MANAGER_ALL = 'ENABLE_INVITES_MANAGER_ALL',
  ENABLE_SIGN_UP_FLOW_V2 = 'ENABLE_SIGN_UP_FLOW_V2',
}

export { FEATURE };
