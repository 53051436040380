import { useTheme } from '@emotion/react';
import { Box, Button, Group } from '@mantine/core';
import {
  IconAlertTriangle,
  IconBell,
  IconInfoCircle,
  IconThumbUp,
  IconX,
} from '@tabler/icons-react';
import { useState } from 'react';

import { Text } from '~/domains/design-system/Text';
import { TruncateText } from '~/domains/design-system/TruncateText';
import IconLocationOff from '~/domains/design-system/icons/IconLocationOff';
import dt from '~/testing';

export const enum BannerType {
  GEO_WARNING = 'geoWarning',
  GEO_ERROR = 'geoError',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
  VANILLA = 'vanilla',
}

type BannerProps = {
  type: BannerType;
  secondaryMessage?: string;
  allowClose?: boolean;
  minimal?: boolean;
  title?: string | JSX.Element;
  Icon?: React.ComponentType<{ size: string }>;
  message?: string | JSX.Element;
  dataTestId?: string;
  truncate?: boolean;
};

const messageTypes = (theme) => ({
  error: {
    backgroundColor: theme.colors.red[9],
    icon: (iconProps) => <IconAlertTriangle {...iconProps} color="white" />,
    fontColor: 'white',
    styles: {
      '& a': {
        color: 'white',
        fontWeight: 'bold',
      },
    },
  },
  geoWarning: {
    backgroundColor: theme.colors.orange[3],
    icon: () => <IconLocationOff color="white" size={24} />,
    fontColor: 'white',
    styles: {
      '& a': {
        color: 'white',
        fontWeight: 'bold',
      },
    },
  },
  geoError: {
    backgroundColor: theme.colors.red[9],
    icon: () => <IconLocationOff color="white" size={24} />,
    fontColor: 'white',
    styles: {
      '& a': {
        color: 'white',
        fontWeight: 'bold',
      },
    },
  },
  warning: {
    backgroundColor: theme.colors.orange[3],
    icon: (iconProps) => <IconAlertTriangle color="white" {...iconProps} />,
    fontColor: 'white',
    styles: {},
  },
  info: {
    backgroundColor: theme.colors.blue[4],
    icon: (iconProps) => <IconInfoCircle {...iconProps} />,
    fontColor: 'black',
    styles: {},
  },
  success: {
    backgroundColor: theme.colors.teal[7],
    icon: (iconProps) => <IconThumbUp {...iconProps} color="white" />,
    fontColor: 'white',
    styles: {},
  },
  vanilla: {
    backgroundColor: theme.white,
    icon: (iconProps) => <IconBell {...iconProps} />,
    fontColor: 'black',
    styles: {},
  },
});

export function Banner({
  title,
  message,
  secondaryMessage,
  type,
  Icon: CustomIcon,
  allowClose = true,
  minimal = false,
  dataTestId = dt.common.components.banner.generic,
  truncate = true,
}: BannerProps) {
  const theme = useTheme();
  const [show, setShow] = useState(true);
  const { backgroundColor, icon: Icon, fontColor, styles } = messageTypes(theme)[type];

  return (
    <Group
      bg={backgroundColor}
      sx={{
        gap: '8px',
        transition: 'height 0.2s ease-in-out, padding 0.2s ease-in-out',
        position: 'relative',
        padding: '8px 16px',
        overflow: 'hidden',
        flexShrink: 0,
        ...(show ? { height: 'auto' } : { height: 0, padding: '0px 48px 0px 16px' }),
        ...(styles ?? {}),
      }}
      position="apart"
      align="center"
      data-test-id={dataTestId}
      noWrap
    >
      <Group noWrap>
        <Box sx={{ flexShrink: 0, display: 'flex' }}>
          {CustomIcon ? <CustomIcon size="24px" /> : <Icon size="24px" />}
        </Box>
        {minimal && truncate && (
          <TruncateText variant="subhead-medium" tooltipLabel={title as string} color={fontColor}>
            {title}
          </TruncateText>
        )}
        {minimal && !truncate && (
          <Text variant="subhead-medium" color={fontColor}>
            {title}
          </Text>
        )}
        {!minimal && (
          <Text variant="subhead-medium" color={fontColor}>
            {message}
          </Text>
        )}
      </Group>
      <Group>
        {secondaryMessage && (
          <Text variant="subhead-medium" color={fontColor} fw={600}>
            {secondaryMessage}
          </Text>
        )}
        {allowClose && (
          <Button
            variant="subtle"
            p={0}
            w={20}
            h={20}
            sx={{
              '&:hover': { backgroundColor: theme.colors.dark[0] },
            }}
            radius="xl"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShow(!show);
            }}
          >
            <IconX color={fontColor} />
          </Button>
        )}
      </Group>
    </Group>
  );
}
