export default {
  breadcrumbs: {
    invites: 'Invites',
    newInvite: 'New Invite',
    reviewInvite: 'Review Invite',
  },
  tabs: {
    followers: 'Followers',
    followersWithCount: 'Followers ({{followersCount}})',
    groups: 'Groups',
    groupsWithCount: 'Groups ({{groupsCount}})',
    email: 'Email',
    ryp: 'RYP',
  },
  sendNewInvite: 'Send new invite',
  reviewInvite: 'Review invite',
  sendInvite: 'Send invite',
  sending: 'Sending...',
  availableInMobileApp: 'Currently available only in mobile apps',
  noResults: {
    title: 'No invites sent',
    text: 'Send a contest invite to followers or copy/paste the contest link.',
  },
  error: {
    title: 'Something went wrong',
    text: 'Please try again later.',
  },
  emails: {
    reset: 'Clear',
    submit: 'Import',
    noResults: {
      title: 'No emails imported',
      text: 'Imported emails will appear here for you to manage and add to the invite.',
    },
  },
  notifications: {
    successBefore: {
      message: 'Inviting players... (this may take a few minutes)',
    },
    successAfter: {
      message__hasPlural: {
        one: '1 invite sent successfully!',
        other: '{{count}} invites sent successfully!',
      },
    },
    error: {
      title: 'Could not send invitations',
      message: 'There was an error sending the invitations. Please try again later.',
    },
  },
  stats: {
    emailInvites: 'EMAIL INVITES',
    info: 'A breakdown of Invites, Entered and Pending is only available for invites sent to followers or contests.',
    entriesAvailable: 'ENTRIES AVAILABLE',
    invites: 'INVITES',
    entered: 'ENTERED',
    pending: 'PENDING',
    invited: 'INVITED ({{totalCount}})',
    sent: 'SENT',
    status: 'STATUS',
  },
  noFollowers: {
    title: 'No followers',
    text: 'Here you’ll be able to select individual followers and add them to your contest invite.',
  },
  noGroups: {
    title: 'No groups',
    text: 'When you run a contest, Splash automatically creates a group of your entrants so you can easily invite past entrants to your new contest.',
  },
  review: {
    info: 'Invites will not be sent to players you already invited or players that already entered this contest.',
    noFollowers: {
      title: 'No followers added',
      text: 'Go back to add followers to the invite.',
    },
    noGroups: {
      title: 'No groups added',
      text: 'Go back to add groups to the invite.',
    },
    noRyp: {
      title: 'No RYP pools added',
      text: 'Go back to add RYP pools to the invite.',
    },
  },
  form: {
    placeholder: `Enter or paste emails to import.\n •  Separate emails with a comma and/or space.\n •  Duplicate emails will not be imported.`,
    info: 'Need to import a large email list or access an invite list from an RYP or OFP contest? Use <link>VIP Support</link>',
  },
  ryp: {
    title: 'Link your RYP account',
    description: 'With linked RYP account, you can invite players from your RYP pools.',
    link: 'Link RYP',
    cta: 'Send new invite',
    allPools: 'All pools ({{poolsCount}})',
    noPools: {
      title: 'No pools',
      text: 'Looks like you don’t have any RYP pools.',
    },
  },
  processing: {
    alert: 'Your invites are still being processed, please wait before you can send more.',
    button: 'Processing...',
  },
};
