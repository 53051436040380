/* eslint-disable no-template-curly-in-string */
export default {
  common: {
    fieldIsRequired: '{{field}} is required',
    fieldIsInvalid: '{{field}} is invalid',
    or: 'or',
    here: 'here',
  },
  signIn: {
    title: 'Log in',
    emailOrUsername: {
      label: 'Email',
      placeholder: 'name@email.com',
      info: 'Please use your account email. For security purposes, you can no longer use your username to log in.',
    },
    password: {
      label: 'Password',
      placeholder: 'Your Password',
    },
    dontHaveAnAccount: "Don't have an account?",
    signUpCTA: "Don't have an account? <signUpLink>Sign up</signUpLink>",
    oauthButton: 'Log In via',
    errors: {
      title: 'Log In Error',
      messages: {
        wrongCredentials: 'Invalid email or password. Please check your credentials and try again.',
      },
    },
  },
  signUp: {
    title: 'Sign up',
    createNewAccount: 'Create new account',
    handle: {
      label: 'Handle',
      placeholder: 'Handle',
      requirements: {
        notAllowedChars: 'Handle contains not allowed characters',
        startsEndsSpecialChars: 'Handle cannot start or end with special characters',
        hasConsecutiveSpecialChars: 'Handle cannot contain consecutive special characters',
        tooShort: 'Handle must not have less than 3 characters',
        tooLong: 'Handle must not have more than 20 characters',
        containsWhiteSpaces: 'Handle must not contain any whitespaces',
        isEmailAddress: 'Handle must not be an email address',
        atLeastOneLetter: 'Handle must contain at least one letter',
      },
    },
    name: {
      label: 'Name',
      placeholder: 'Your name',
    },
    email: {
      label: 'Email',
      placeholder: 'name@email.com',
      requirements: {
        restricted: 'We are unable to create an account with this email address.',
      },
    },
    password: {
      label: 'Password',
      placeholder: 'Your Password',
      requirements: {
        minLength: 'Includes at least 8 characters',
        number: 'Includes number',
        lowercase: 'Includes lowercase letter',
        uppercase: 'Includes uppercase letter',
        specialChar: 'Includes special symbol',
      },
    },
    referralCode: {
      label: 'Referral Code (optional)',
      placeholder: 'Referral Code (optional)',
      error: 'Invalid referral code',
      info: 'You and your referrer will receive rewards after you enter a $10+ contest and it starts.',
      success:
        'Code verification successful! You and your referrer will receive rewards after you enter a $10+ contest and it starts.',
    },
    referralBonus: {
      header: 'Sign up complete!',
      title: 'YOU UNLOCKED A BONUS!',
      copy: 'Redeem your new account bonus and start playing!',
      subTitle: 'NEW ACCOUNT BONUS',
      rewardAmount: '${{amount}} FREE',
      rewardAmountCopy:
        'Receive ${{amount}} from us when you deposit your first ${{amount}} or more.',
      rewardRedeem: 'Redeem',
      disclaimer:
        'Due to third party requirements, deposit via Venmo on the Splash Sports app requires that you set your default browser to Safari (only required for the first deposit via Venmo).',
    },
    confirmation: {
      title: 'Confirmation',
      copy: 'Does this info you provided match exactly what’s on your license? If not, your account may get rejected.',
      confirmButton: 'Yes, this info matches my license',
      goBackButton: 'Go back',
    },
    cta: 'Play Now',
    goToAccount: 'Go to my account',
    alreadyLoggedIn: "You're already signed in",
    alreadyHaveAnAccount: 'Already have an account?',
    signInCTA: 'Already have an account? <signInLink>Sign in</signInLink>',
    logInCTA: 'Already have an account? <signInLink>Log in</signInLink>',
    logIn: 'Log in',
    legalBlurb:
      'Clicking "Play Now" confirms you are age 18+ (19+ in AL & 21+ MA, IA, LA, AZ) and agree to our <termsLink></termsLink> & <privacyLink></privacyLink>. You\'ll also get offers and messages from the SplashSports companies. Find out where Splash Sports is <whereCanIPlayLink></whereCanIPlayLink>.',
    legalBlurbV2:
      'Clicking "Continue" confirms you are age 18+ (19+ in AL & 21+ MA, IA, LA, AZ) and agree to our <termsLink></termsLink> & <privacyLink></privacyLink>. You\'ll also get offers and messages from the SplashSports companies. Find out where Splash Sports is <whereCanIPlayLink></whereCanIPlayLink>.',
    gamblingProblemBlurb:
      'If you or someone you know has a gambling problem and wants help, call <phoneLink></phoneLink> or chat <chatLink></chatLink>',
    terms: 'Terms',
    privacyPolicy: 'Privacy Policy',
    whereCanIPlay: 'currently available',
    infoCallout: 'Make sure this information matches exactly what’s on your driver’s license!',
    ssoCopy: 'Sign in via',
    headers: {
      email: {
        title: "WHAT'S YOUR EMAIL?",
        copy: "You'll use your email to log in to Splash.",
      },
      password: {
        title: 'SET YOUR PASSWORD',
        copy: 'Passwords must be at least 8 characters long and contain 1 uppercase, 1 lower case and 1 special character.',
      },
      handle: {
        title: 'CREATE A HANDLE',
        copy: 'This is how other players will find you on Splash. You can edit your handle by going to My Channel > Edit.',
      },
      name: {
        title: "WHAT'S YOUR NAME AND DOB?",
        copy: 'Enter your full name, as it appears on your license.',
      },
      address: {
        title: "WHAT'S YOUR ADDRESS?",
        copy: 'This information is required to verify your identity.',
      },
    },
    steps: {
      step1: 'Step 1',
      step2: 'Step 2',
      step3: 'Step 3',
      step4: 'Step 4',
      step5: 'Step 5',
      status: '{{current}} of {{total}}',
    },
  },
  eoValueProp: {
    title: 'Benefits are as easy as 1, 2, 3',
    linkYourExistingAccount: 'Sign up or link your existing RunYourPool Account',
    importedYourInfo: 'Transform the way you play.',
    verifyDetails: 'Make sure the following details are correct.',
    easilyImportInfo: 'Play against your friends for cash',
    inviteConnectionsToSplash: 'Earn money for creating the games everyone wants',
    getMoneyForYourFirstContest: 'We handle all entry fees and payouts for commissioners',
    blurb: {
      main: 'Sign up with RYP',
    },
  },
  resetPassword: {
    title: 'Reset Password',
    subtitle: 'Enter your email address and we will send you a link to reset your password.',
  },
  forgotPassword: {
    cta: 'Forgot Password?',
    title: 'Did you forget your password?',
    subtitle: 'Enter your email address and we will send you a link to reset your password.',
    linkSentTitle: 'Reset Password Link sent!',
    linkSentSubtitle:
      'If we have found an account linked to your handle/email, you will receive a link in your email inbox',
    fields: {
      emailOrUsername: 'Email',
    },
    errors: {
      minLength: 'This field cannot be empty',
    },
    errorTitle: 'Forgot Password Error',
    errorMessage: 'There was an error while requesting forgot password link',
    buttonLabel: 'Request password link',
    support:
      "If you have forgotten your email or can't access your account <link>contact support</link>.",
  },
  confirmPassword: {
    title: 'Choose new password',
    subtitle: 'Enter your new password and then click the button to change it.',
    yourNewPassword: 'Your new password',
    successTitle: 'Your password has been changed',
    successMessage: 'You can now log in with your new password',
    errorTitle: 'Confirm Password Error',
    errorMessage: 'There was an error while resetting your password.',
    buttonLabel: 'Reset Password',
  },
  errors: {
    password: {
      tooShort: 'Password should include at least 8 characters',
      needsUppercase: 'Password should have at least one uppercase letter',
      needsLowercase: 'Password should have at least one lowercase letter',
      needsNumber: 'Password should have at least one number',
      needsSpecialCharacter: 'Password should have at least one special character',
    },
  },
  signUpPromo: {
    subtitle: 'Never miss out on the action!',
    copy: 'Download the Splash Sports mobile app and turn on notifications to get pick reminders, the most up-to-date gameplay experience, social features, and additional payment methods!',
  },
  signUpActions: {
    title: 'Download and sign up on mobile',
    fastestOption: 'Fastest',
    downloadIos: 'Download for iOS',
    downloadAndroid: 'Download for Android',
    signUpVia: 'Sign up via',
    signUpOnWeb: 'Sign up on web',
    continueOnWeb: 'Continue on web',
    continue: 'Continue',
  },
};
