import React, { useCallback, useState } from 'react';
import { Button, useMantineTheme, Menu } from '@mantine/core';
import { IconDotsVertical, IconShare } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import { useCopyContestLink } from '~/domains/contest/hooks/useCopyContestLink';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import useContestInvite from '~/domains/contest/domains/invite/hooks/useContestInvite';
import InviteModal from '~/domains/contest/domains/invite/components/InviteModal';
import { InviteCTA } from '~/domains/contest/domains/invite/components/InviteCTA';
import type { MyUserContest } from '~/domains/contest/domains/myContests/types/userContest';

// This props are being passed by Menu.Target component indirectly
type MenuButtonProps = {
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  'data-expanded'?: boolean;
};

// eslint-disable-next-line react/display-name
const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (props: MenuButtonProps, ref) => {
    const theme = useMantineTheme();
    const { onClick } = props;

    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        if (onClick) {
          onClick(e);
        }
      },
      [onClick]
    );

    return (
      <Button variant="subtle" p={0} {...props} onClick={handleClick} ref={ref}>
        <IconDotsVertical color={theme.colors.gray[5]} />
      </Button>
    );
  }
);

type ContestCardMenuProps = {
  contest: Contest | MyUserContest;
};

/** @deprecated - used solely for legacy Invites Manager. Remove it once the new invites manager is enabled by default */
function ContestCardMenu({ contest }: ContestCardMenuProps) {
  const { handleCopyLink } = useCopyContestLink(contest.id, contest.name);
  const { t } = useTranslation('contest');
  const { isInviteCTAShown } = useContestInvite();
  const [inviteModalContestId, setInviteModalContestId] = useState<string>(null);
  return (
    <>
      <Menu position="left">
        <Menu.Target>
          <MenuButton />
        </Menu.Target>

        <Menu.Dropdown>
          {isInviteCTAShown ? (
            <InviteCTA
              contestId={contest.id}
              isContestLoaded={!!contest}
              onClick={() => setInviteModalContestId(contest.id)}
              variant="text"
              leftIcon={<IconShare />}
            />
          ) : (
            <Menu.Item icon={<IconShare />} onClick={handleCopyLink}>
              {t('common.actions.copyLink')}
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <InviteModal
        opened={!!inviteModalContestId}
        onClose={() => setInviteModalContestId(null)}
        contestId={inviteModalContestId}
        contestName={contest.name}
      />
    </>
  );
}

export default ContestCardMenu;
