import { createStyles } from '@mantine/core';
import Link from 'next/link';
import type { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

import { textStyles } from '~/domains/design-system/Text';

export type ButtonProps = {
  variant?:
    | 'primary-fill'
    | 'primary-stroke'
    | 'secondary-stroke'
    | 'secondary-fill'
    | 'secondary-inverted-stroke'
    | 'secondary-inverted-fill'
    | 'tertiary-fill'
    | 'text';
  size?: 'small' | 'medium' | 'large' | 'small-icon' | 'large-icon';
  mode?: 'default' | 'linkButton' | 'unstyledButton';
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
};

const useStyles = createStyles((theme) => ({
  base: {
    border: 0,
    borderRadius: '4px',
    padding: '0px 18px',
    cursor: 'pointer',
    fontFamily: theme.fontFamily,

    'a&': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&:disabled': {
      cursor: 'not-allowed',
    },

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',

      '&:not(:disabled):hover': {
        opacity: 0.5,
      },
    },
  },
  'primary-fill': {
    color: theme.colors.gray2[7],
    background: theme.colors.blue[4],

    '&:disabled': {
      color: theme.colors.gray2[4],
      background: theme.colors.blue[1],
    },
  },
  'primary-stroke': {
    color: theme.colors.blue[4],
    background: 'transparent',
    border: `2px solid ${theme.colors.blue[4]}`,

    '&:disabled': {
      color: theme.colors.blue[1],
      borderColor: theme.colors.blue[1],
    },
  },
  'secondary-stroke': {
    background: 'transparent',
    color: theme.colors.gray2[7],
    border: `2px solid ${theme.colors.gray2[7]}`,

    '&:disabled': {
      color: theme.colors.gray2[3],
      borderColor: theme.colors.gray2[3],
    },
  },
  'secondary-fill': {
    color: theme.white,
    background: theme.colors.gray2[7],

    '&:disabled': {
      color: theme.colors.gray2[1],
      background: theme.colors.gray2[3],
    },
  },
  'secondary-inverted-fill': {
    color: theme.colors.gray2[7],
    background: theme.white,

    '&:disabled': {
      background: theme.colors.gray2[1],
      color: theme.colors.gray2[3],
    },
  },
  'secondary-inverted-stroke': {
    background: 'transparent',
    color: theme.white,
    border: `2px solid ${theme.white}`,

    '&:disabled': {
      color: theme.colors.gray2[3],
      borderColor: theme.colors.gray2[3],
    },
  },
  'tertiary-fill': {
    color: theme.colors.gray2[6],
    background: theme.colors.gray2[0],

    '&:disabled': {
      color: theme.colors.gray2[2],
      background: theme.colors.gray2[0],
    },
  },
  text: {
    background: 'transparent',
    color: theme.colors.gray[9],

    '&:disabled': {
      color: theme.colors.gray[6],
    },
  },
  small: { ...textStyles['label-small'], height: '32px', padding: '0px 15px' },
  'small-icon': {
    height: '32px',
    width: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  },
  medium: { ...textStyles['label-medium'], height: '40px' },
  large: { ...textStyles['label-large'], height: '48px' },
  'large-icon': {
    ...textStyles['label-large'],
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    'svg:first-child': { marginRight: '8px' },
    'svg:last-child': { marginLeft: '8px' },
  },
  fullWidth: { width: '100%' },
  linkButton: {
    padding: 0,
    height: 'auto',
    color: theme.colors.blue[7],
    background: 'transparent',
    textDecoration: 'none',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
    },
  },
  unstyledButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    color: 'inherit',
    cursor: 'pointer',
  },
}));

const ButtonFactory = <E extends React.ElementType>(
  component: E,
  defaultMode: ButtonProps['mode'] = 'default'
) =>
  // eslint-disable-next-line react/display-name
  forwardRef<E, ButtonProps & React.ComponentPropsWithoutRef<E>>(
    (
      {
        children,
        className,
        variant = 'primary-fill',
        size = 'large',
        mode = defaultMode,
        fullWidth,
        ...props
      },
      ref
    ) => {
      const { classes, cx } = useStyles();
      return React.createElement(
        component,
        {
          ref,
          className: cx(
            classes.base,
            classes[variant],
            classes[size],
            classes[mode],
            fullWidth && classes.fullWidth,
            className
          ),
          ...props,
        },
        children
      );
    }
  );

export const Button = ButtonFactory('button');
Button.displayName = 'Button';

export const ButtonLink = ButtonFactory(Link);
ButtonLink.displayName = 'ButtonLink';

export const LinkButton = ButtonFactory('button', 'linkButton');
LinkButton.displayName = 'LinkButton';

export const ButtonLabel = ButtonFactory('label');
Button.displayName = 'ButtonLabel';

export const UnstyledButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => {
  const { classes, cx } = useStyles();
  return <button className={cx(classes.unstyledButton, className)} {...props} ref={ref} />;
});

UnstyledButton.displayName = 'UnstyledButton';

export const UnstyledButtonLink = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>(({ className, href, ...props }, ref) => {
  const { classes, cx } = useStyles();
  return (
    <Link href={href} className={cx(classes.unstyledButton, className)} {...props} ref={ref} />
  );
});

UnstyledButtonLink.displayName = 'UnstyledButtonLink';
