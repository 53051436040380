export default {
  components: {
    cta: 'invite.components.cta',
    invitesModalLeft: {
      email: 'invite.components.invitesModalLeft.email',
    },
    inviteModalRight: {
      container: 'invite.components.inviteModalRight.container',
      emailAccordionButton: 'invite.components.inviteModalRight.emailAccordionButton',
      email: {
        container: 'invite.components.inviteModalRight.email.container',
        closeButton: 'invite.components.inviteModalRight.email.closeButton',
      },
    },
    invitesViaEmail: {
      emailInput: 'invite.components.invitesViaEmail.emailInput',
      addButton: 'invite.components.invitesViaEmail.addButton',
    },
  },
};
