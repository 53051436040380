import { createStyles } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import type { PropsWithChildren } from 'react';
import { Drawer as BaseDrawer } from 'vaul';

import { Button } from '../../Button';

const useStyles = createStyles((theme) => ({
  overlay: {
    position: 'fixed',
    inset: 0,
    opacity: '0.7 !important',
    zIndex: 1,
    backgroundColor: theme.colors.gray2[9],
  },
  content: {
    backgroundColor: theme.colors.gray2[0],
    display: 'flex',
    flexDirection: 'column',
    borderRadius: `${theme.other.spacing._32}px ${theme.other.spacing._32}px 0 0`,
    height: '70vh',
    marginTop: theme.spacing.xl * 6,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    maxWidth: theme.other.layout.maxWidth,
    margin: '0 auto',

    [theme.fn.smallerThan('md')]: {
      maxHeight: '100svh',
      height: 'auto',
    },
  },
  innerContent: {
    width: '100%',
    padding: theme.spacing.md,
    backgroundColor: theme.white,
    borderRadius: `${theme.other.spacing._32}px ${theme.other.spacing._32}px 0 0`,
    flex: 1,
    overflow: 'auto',
  },
  handle: {
    margin: '0 auto',
    width: 56,
    height: 4,
    flexShrink: 0,
    borderRadius: theme.radius.md,
    backgroundColor: '#909296',
  },
  close: {
    top: 0,
    left: 0,
    height: 24,
    padding: 0,
    position: 'absolute',
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing.xl,
  },
  maxContent: {
    position: 'relative',
    maxWidth: theme.other.layout.maxWidth,
    margin: '0 auto',
  },
}));

type DrawerProps = {
  onToggle?: (open: boolean) => void;
  onClose: () => void;
  title?: string | React.ReactNode;
  open?: boolean;
  'data-test-id'?: string;
  contentProps?: {
    className?: string;
  };
  classes?: Partial<ReturnType<typeof useStyles>['classes']> & {
    innerContent?: string;
  };
  elements?: {
    header?: JSX.Element;
  };
  isModal?: boolean;
};

export function Drawer({
  children,
  title,
  onToggle,
  onClose,
  open,
  contentProps,
  elements,
  isModal = true,
  ...rest
}: PropsWithChildren<DrawerProps>) {
  const { cx, classes } = useStyles();

  return (
    <BaseDrawer.Root
      modal={isModal}
      disablePreventScroll
      onClose={onClose}
      onOpenChange={onToggle}
      open={open}
    >
      <BaseDrawer.Portal>
        <BaseDrawer.Overlay className={cx(classes.overlay, rest.classes?.overlay)} />
        <BaseDrawer.Content className={cx(classes.content, rest.classes?.content)}>
          <div
            className={cx(classes.innerContent, rest.classes?.innerContent)}
            data-test-id={rest['data-test-id']}
          >
            {elements?.header ?? (
              <>
                <div className={classes.handle} />
                <div className={classes.maxContent}>
                  <Button
                    onClick={onClose}
                    variant="text"
                    className={cx(classes.close, rest.classes?.close)}
                  >
                    <IconX />
                  </Button>
                  <BaseDrawer.Title className={classes.title}>{title}</BaseDrawer.Title>
                </div>
              </>
            )}
            <div className={cx(classes.maxContent, contentProps?.className)}>{children}</div>
          </div>
        </BaseDrawer.Content>
      </BaseDrawer.Portal>
    </BaseDrawer.Root>
  );
}
