import React from 'react';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

import useStyles from './FullScreenMessage.styles';
import { Title } from '~/domains/design-system/Title';
import { Text } from '~/domains/design-system/Text';

type FullScreenMessageProps = {
  title?: JSX.Element | string;
  subtitle?: JSX.Element | string;
  icon?: JSX.Element;
  actions?: JSX.Element;
};

export function FullScreenMessage({ title, subtitle, icon, actions }: FullScreenMessageProps) {
  const isMobile = useIsMobile();
  const { classes, theme } = useStyles({ isMobile });

  return (
    <div className={classes.container}>
      {icon && <div className={classes.iconWrapper}>{icon}</div>}
      {title && (
        <Title variant="headline-xxl" maw="500px" color={theme.colors.gray2[6]}>
          {title}
        </Title>
      )}
      {subtitle && (
        <Text variant="body-large" color={theme.colors.gray2[6]} maw="400px">
          {subtitle}
        </Text>
      )}
      {actions}
    </div>
  );
}
