import React, { useState, useRef, useLayoutEffect } from 'react';
import { type TextProps } from '@mantine/core';

import { Text } from '~/domains/design-system/Text';

import { Tooltip } from '../common/components/Tooltip/Tooltip';

type TruncateProps = {
  children: React.ReactNode;
  tooltipLabel: string;
  variant: typeof Text.defaultProps.variant;
  maxWidth?: number | string;
  'data-test-id'?: string;
} & Omit<TextProps, 'variant'>;

export function TruncateText({
  children,
  variant,
  tooltipLabel,
  maxWidth = '100%',
  ...rest
}: TruncateProps) {
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const { scrollWidth, clientWidth } = textRef.current;
        setIsTruncated(scrollWidth > clientWidth);
      }
    };

    checkTruncation();

    const resizeObserver = new ResizeObserver(checkTruncation);
    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        resizeObserver.unobserve(textRef.current);
      }
    };
  }, [children, maxWidth]);

  return (
    <Tooltip isRendered={isTruncated} label={tooltipLabel}>
      <div
        ref={textRef}
        style={{
          maxWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Text data-test-id={rest['data-test-id']} variant={variant} {...rest}>
          {children}
        </Text>
      </div>
    </Tooltip>
  );
}
